
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import { setGATId, setGATUserId } from '@/utils/cookies'

@Component({
  name: 'guanaitong',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
    @Provide() public authCode: string = '';

    public async created () {
      console.log('关爱通xiaoshi')
      this.authCode = getCurrentQuery('auth_code')
      setGATId(this.authCode)
      this.doAuth()
    }
    @Emit()
    public async doAuth () {
      const data: any = {
        authCode: decodeURIComponent(this.authCode)
      }
      try {
        const res = await httpHelper.post({
          url: 'authorize/guanaitong',
          contentType: 'application/x-www-form-urlencoded',
          data: data,
          headers: {
            Authorization: configs.basicAuthorization
          }
        })
        if (res.code === 0) {
          setGATUserId(res.value.extend.benlaigatUserID)
          console.log(res.value.extend)
          location.replace(httpHelper.mRoot)
        }
      } catch (err) {
        this.goTo(err, 9, 22)
      }
    }
}
